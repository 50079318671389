import { Link } from "react-router-dom";
import "./Home.less";
import "./HomeCollection.less";
import "./gallery.less";
import "./MenuHome.css";

export default function Home() {
  return (
    <body className="homegallery home homecollection">
      <div className="menuhome">
        <div className="btn" id="phone">
          <span htmlFor="phone" className="img_lbl" id="phone_span">
            <a href="tel:+4528772416">
              <img
                src="icons/phone.png"
                alt="Phone icon"
                className="icon"
                id="phone_img"
              />
            </a>
          </span>
        </div>
        <a
          href="mailto:frederikbarasinski@gmail.com"
          className="btn"
          id="contact"
        >
          <span htmlFor="contact" className="img_lbl">
            <img src="icons/mail.png" alt="Mail icon" className="icon"></img>
          </span>
        </a>
        <a
          href="https://www.facebook.com/fbarasinski"
          target="_blank"
          rel="noreferrer"
          className="btn"
          id="contact"
        >
          <span htmlFor="contact" className="img_lbl">
            <img
              src="icons/face.png"
              alt="Facebook icon"
              className="icon"
            ></img>
          </span>
        </a>
        <a
          href="https://www.instagram.com/frederikbarasinski/"
          target="_blank"
          className="btn"
          rel="noreferrer"
          id="contact"
        >
          <span htmlFor="contact" className="img_lbl">
            <img
              src="icons/insta.png"
              alt="Instagram icon"
              className="icon"
            ></img>
          </span>
        </a>
      </div>
      <div id="app" defer>
        <div className="title">
          <div className="title-inner">
            <div className="cafe">
              <div className="cafe-inner">Frederik</div>
            </div>
            <div className="mozart">
              <div className="mozart-inner">Barasinski</div>
            </div>
          </div>
        </div>
        <div className="under-info">
          Photographer & Content Creator
          <br />
          Based in Copenhagen Denmark
        </div>
        <div className="image">
          <img src="images/img_1/fred1.jpg" alt="Frederik Barasinski"></img>
        </div>
      </div>
      <div className="content" defer>
        <div className="gallery">
          {/* ================================================== */}
          {/* =====================COLUMN 1===================== */}
          {/* ================================================== */}
          <div className="gallery__column">
            <div className="object__wrapper">
              <Link
                to="/collection/Euroman - Malte Ebert"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/Euroman - Malte Ebert/1.jfif"
                    alt="Euroman - Malte Ebert"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    Euroman - Malte Ebert
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link
                to="/Collection/Gulddreng EM 2021"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/Gulddreng EM 2021/1.jfif"
                    alt="Gulddreng EM 2021"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    Gulddreng EM 2021
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/BASKE" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/BASKE/1.jpg"
                    alt="BASKE"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">BASKE</figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link
                to="/collection/Vinny's Readers Club - Merch"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/Vinny's Readers Club - Merch/1.jpg"
                    alt="Vinny's Readers Club - Merch"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    Vinny's Readers Club - Merch
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/OMEGA WATCHES" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/OMEGA WATCHES/1.jpg"
                    alt="OMEGA WATCHES"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    OMEGA WATCHES
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/DREW SYCAMORE" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/DREW SYCAMORE/1.jpg"
                    alt="DREW SYCAMORE"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    DREW SYCAMORE
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/COPENHAGEN STATE" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/COPENHAGEN STATE/1.jpg"
                    alt="COPENHAGEN STATE"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    COPENHAGEN STATE
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
          {/* ================================================== */}
          {/* =====================COLUMN 2===================== */}
          {/* ================================================== */}
          <div className="gallery__column">
            <div className="object__wrapper">
              <Link to="/collection/CHRISTOPHER" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/CHRISTOPHER/1.jfif"
                    alt="CHRISTOPHER"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    CHRISTOPHER
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/LUKAS GRAHAM" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/LUKAS GRAHAM/1.jpg"
                    alt="LUKAS GRAHAM"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    LUKAS GRAHAM
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/SCARLET PLEASURE" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/SCARLET PLEASURE/1.jpg"
                    alt="SCARLET PLEASURE"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    SCARLET PLEASURE
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/FAR FROM HOME" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/FAR FROM HOME/1.jpg"
                    alt="FAR FROM HOME"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    FAR FROM HOME
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link
                to="/collection/SKAGERRAK NORDIC GIN"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/SKAGERRAK NORDIC GIN/1.jpg"
                    alt="SKAGERRAK NORDIC GIN"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    SKAGERRAK NORDIC GIN
                  </figcaption>
                </figure>
              </Link>
            </div>

            <div className="object__wrapper">
              <Link
                to="/collection/DREW SYCAMORE & GESTUZ"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/DREW SYCAMORE & GESTUZ/1.jpg"
                    alt="DREW SYCAMORE & GESTUZ"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    DREW SYCAMORE & GESTUZ
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/HOUSE OF VINCENT" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/HOUSE OF VINCENT/1.jpg"
                    alt="HOUSE OF VINCENT"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    HOUSE OF VINCENT
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>

          {/* ================================================== */}
          {/* =====================COLUMN 3===================== */}
          {/* ================================================== */}
          <div className="gallery__column">
            <div className="object__wrapper">
              <Link
                to="/collection/66 NORTH X EMILIE LILJA"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/66 NORTH X EMILIE LILJA/1.jpg"
                    alt="66 NORTH X EMILIE LILJA"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    66 NORTH X<br />
                    EMILIE LILJA
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link
                to="/collection/66 NORTH X ALEX HØGH"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/66 NORTH X ALEX HØGH/1.jpg"
                    alt="66 NORTH X ALEX HØGH"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    66 NORTH X<br />
                    ALEX HØGH
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link
                to="/collection/66 NORTH X JOTUNHEIMEN"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/66 NORTH X JOTUNHEIMEN/1.jpg"
                    alt="66 NORTH X JOTUNHEIMEN"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    66 NORTH X JOTUNHEIMEN
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link
                to="/collection/66 NORTH X GREENLAND"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/66 NORTH X GREENLAND/1.jpg"
                    alt="66 NORTH X GREENLAND"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    66 NORTH X GREENLAND
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/LITTGLOSS" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/LITTGLOSS/1.jpg"
                    alt="LITTGLOSS"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    LITTGLOSS
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/MATAS" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/MATAS/1.jpg"
                    alt="MATAS"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">MATAS</figcaption>
                </figure>
              </Link>
            </div>
          </div>
          {/* ================================================== */}
          {/* =====================COLUMN 4===================== */}
          {/* ================================================== */}
          <div className="gallery__column">
            <div className="object__wrapper">
              <Link to="/collection/GESTUZ" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/GESTUZ/1.jpg"
                    alt="GESTUZ"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">GESTUZ</figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/GESTUZ 2022" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/GESTUZ 2022/1.jpg"
                    alt="GESTUZ 2022"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    GESTUZ 2022
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link
                to="/collection/Mine Venner Er Sindssyge"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/Mine Venner Er Sindssyge/1.jpg"
                    alt="Mine Venner Er Sindssyge"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    Mine Venner Er Sindssyge
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/Emil Lange" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/Emil Lange/1.jpg"
                    alt="Emil Lange"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    Emil Lange
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link to="/collection/LOUIS VUITTON" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src="images/LOUIS VUITTON/1.jpg"
                    alt="LOUIS VUITTON"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    LOUIS VUITTON
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div className="object__wrapper">
              <Link
                to="/collection/RENEE TOFT SIMONSEN & OSLO SKIN LAB"
                className="gallery__link"
              >
                <figure className="gallery__thumb">
                  <img
                    src="images/RENEE TOFT SIMONSEN & OSLO SKIN LAB/1.jpg"
                    alt="RENEE TOFT SIMONSEN & OSLO SKIN LAB"
                    className="gallery__image"
                    defer
                  ></img>
                  <figcaption className="gallery__caption">
                    RENEE TOFT SIMONSEN & OSLO SKIN LAB
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
