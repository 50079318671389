import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchCloudObjects } from "../../js/fetchCloudObjects";
import "./MenuCollection.css";
import "./gallery2.less";

const scrollIntoViewOptions = {
  behavior: "smooth",
  block: "center",
  inline: "nearest",
};

export default function CollectionPage() {
  const { collection } = useParams();
  const [urls, setUrls] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    fetchCloudObjects(collection).then((urls) => setUrls(urls));
  }, [collection]);

  useEffect(() => {
    const galleryImages = [...document.querySelectorAll(".gallery__image")];

    if (galleryImages.length > 0)
      galleryImages[index].scrollIntoView(scrollIntoViewOptions);
  }, [index]);

  return (
    <div className="gallery2">
      <div className="collectionmenu">
        <div className="col_btn" id="phone">
          <span htmlFor="phone" className="img_lbl" id="phone_span">
            <a href="tel:+4528772416">
              <img
                src="/icons/phone.png"
                alt="Phone icon"
                className="icon"
                id="phone_img"
              />
            </a>
          </span>
        </div>
        <a
          href="mailto:frederikbarasinski@gmail.com"
          className="col_btn"
          id="contact"
        >
          <span htmlFor="contact" className="img_lbl">
            <img src="/icons/mail.png" alt="Mail icon" className="icon"></img>
          </span>
        </a>
        <a
          href="https://www.facebook.com/fbarasinski"
          target="_blank"
          rel="noreferrer"
          className="col_btn"
          id="contact"
        >
          <span htmlFor="contact" className="img_lbl">
            <img
              src="/icons/face.png"
              alt="Facebook icon"
              className="icon"
              defer
            ></img>
          </span>
        </a>
        <a
          href="https://www.instagram.com/frederikbarasinski/"
          target="_blank"
          className="col_btn"
          rel="noreferrer"
          id="contact"
        >
          <span htmlFor="contact" className="img_lbl">
            <img
              src="/icons/insta.png"
              alt="Instagram icon"
              className="icon"
            ></img>
          </span>
        </a>
      </div>

      <figcaption className="gallery__caption">
        {collection}
        <Link to="/">
          <img src="../icons/x.png" alt="" id="x" />
        </Link>
        <button
          className="controls"
          id="up"
          style={{ color: "black" }}
          onClick={() => {
            const max =
              [...document.querySelectorAll(".gallery__image")].length - 1;
            if (index <= 0) {
              setIndex(max);
            } else {
              setIndex(index - 1);
            }
          }}
        >
          <img className="controls_img" src="../icons/up.png" alt="up" />
        </button>
        <button
          className="controls"
          id="down"
          style={{ color: "black" }}
          onClick={() => {
            const max =
              [...document.querySelectorAll(".gallery__image")].length - 1;
            if (index >= max) {
              setIndex(0);
            } else {
              setIndex(index + 1);
            }
          }}
        >
          <img className="controls_img" src="../icons/down.png" alt="down" />
        </button>
      </figcaption>

      <div className="content">
        <div className="gallery">
          <div className="gallery__column">
            {urls.map((url) => (
              <div className="object__wrapper" key={url}>
                <div className="gallery__link" target="_blank">
                  <figure className="gallery__thumb"></figure>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div>
                                <div>
                                  <div>
                                    <div>
                                      <div>
                                        <div className="image__wrapper">
                                          <img
                                            className="gallery__image"
                                            src={url}
                                            alt={url}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
