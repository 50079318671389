import { Route, Routes } from "react-router-dom";
import CollectionPage from "./pages/collection/CollectionPage";
import Home from "./pages/home/Home";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="collection/:collection" element={<CollectionPage />} />
      </Routes>
    </div>
  );
}

export default App;
