const fetch = require("node-fetch");

export async function fetchCloudObjects(colID) {
  const url = `https://storage.googleapis.com/storage/v1/b/barasinski_photos/o?prefix=${colID}/`;

  const publicURL = "https://storage.googleapis.com/barasinski_photos/";

  let json = await fetch(url).then((res) => res.json());

  const files = [];

  for (const ele of json.items) {
    if (ele.name.includes(".jpg") || ele.name.includes(".JPG") || ele.name.includes(".png") || ele.name.includes(".PNG") || ele.name.includes(".jfif") || ele.name.includes(".JFIF"))
      files.push(publicURL + ele.name);
  }

  return files;
}
